import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/features/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUserPlus, faUserTie, faBullhorn, faConciergeBell, faUsers, faDollarSign,  faListAlt } from '@fortawesome/free-solid-svg-icons';
import HeaderAdmin from '../components/HeaderAdmin';
import AddSession from '../forms/AddSession';
import AddClient from '../forms/AddClient';
import AddWorker from '../forms/AddWorker';
import Clients from '../lists/Clients.js';
import { getClients } from '../redux/features/clientSlice.js';
import Sessions from '../lists/Sessions.js';
import Spinner from '../components/Spinner.js';
import { getUserWorkers } from '../redux/features/workerSlice.js';
import { getUserServices } from '../redux/features/serviceSlice.js';
import { getUserSessions } from '../redux/features/sessionSlice.js';
import AddService from '../forms/AddService.js';
import SessionsHistory from '../lists/SessionsHistory.js';
import ClientsHistory from '../lists/ClientsHistory.js';
import { getUserArchives } from '../redux/features/archiveSlice.js';
import SendMessage from '../campains/SendMessage.js';
import { getSMS } from '../redux/features/smsSlice.js';
import { getUserCategories } from '../redux/features/categorySlice.js';

const Admin = React.memo(() => {
  const [activeForm, setActiveForm] = useState('AddSession');
  const user = useSelector(selectUser);
  const loading = useSelector((state) => state.client.loading);
  const userId = user?.result._id;
  const clients = useSelector((state) => state.client.clients);
  const workers = useSelector((state) => state.worker.workers);
  const services = useSelector((state) => state.service.services);
  const sessions = useSelector((state) => state.session.sessions);
  const categories = useSelector((state) => state.category.categories);
  const archives = useSelector((state)=>state.archive.archives);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const clientsView = useCallback(()=>{setActiveForm('Clients')});
  const addSessionsView = useCallback(()=>{setActiveForm('AddSession')});
  const sessionsView = useCallback(()=>{setActiveForm('Sessions')});
  const campainsView = useCallback(()=>{setActiveForm('Campains')});
  const clientsHistoryView = ((par)=>{
    setSelectedPhone(par);
    setActiveForm('ClientHistory')});
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId) {
      dispatch(getClients({ userId }));
      dispatch(getUserWorkers({userId}));
      dispatch(getUserServices({userId}));
      dispatch(getUserSessions({userId}));
      dispatch(getUserCategories({userId}));
      dispatch(getUserArchives({userId}));
    }
  }, [dispatch, userId]);

  const forms = {
    AddSession: ( <AddSession goToSessions={sessionsView} goToAddSession={addSessionsView} workers={workers} services={services} categories={categories} />),
    Sessions: ( <Sessions  goToAddSession={addSessionsView} sessions={sessions} />),
    SessionsHistory: ( <SessionsHistory goToClients={clientsView} archives={archives}/>),
    AddClient: (<AddClient goToClients={clientsView} />),
    Clients: (<Clients data={clients} setClientHistory={(par)=>clientsHistoryView(par)}/>),
    AddWorker: (<AddWorker goToAddSession={addSessionsView} workers={workers} />),
    AddService: (<AddService categories={categories} goToAddSession={addSessionsView} services={services} />),
    CreateCampaign:(<SendMessage  data={clients} goToCampains={campainsView}/>),
    Finance: <div><h2 className="text-3xl font-bold text-white mb-6">Finance</h2></div>,
    ClientHistory: (<ClientsHistory archives={archives} selectedPhone={selectedPhone} />)
  };



  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200">
      {/* Header */}
      <div className="w-full fixed top-0 left-0 z-10">
        <HeaderAdmin />
      </div>
      {loading && <Spinner />}
      {/* Main Content */}
      <div className="flex-grow mt-16 flex ">
        {/* Sidebar with buttons */}
        <div className="w-1/6 flex flex-col items-center  space-y-4 py-8  text-sm">
          {[
            { title: "Add Session", icon: faCalendarAlt, form: 'AddSession' },
            { title: "Sessions", icon: faListAlt, form: 'Sessions' },
            { title: "History", icon: faListAlt, form: 'SessionsHistory' },
            { title: "Add Client", icon: faUserPlus, form: 'AddClient' },
            { title: "Clients", icon: faUsers, form: 'Clients' },
            { title: "Add Worker", icon: faUserTie, form: 'AddWorker' },
            { title: "Add Service", icon: faConciergeBell, form: 'AddService' },
            { title: "Create Campaign", icon: faBullhorn, form: 'CreateCampaign' },
            { title: "Finance", icon: faDollarSign, form: 'Finance' },
          ].map((section, index) => (
            <button
              key={index}
              onClick={() => setActiveForm(section.form)}
              className="w-full  h-16 bg-white rounded-sm text-xs  text-green-500 flex items-center justify-center hover:bg-green-500 hover:text-white transition-colors duration-300"
            >
              <div className="flex flex-col items-center">
                <FontAwesomeIcon icon={section.icon} className="text-1xl" />
                <span className="text-sm">{section.title}</span>
              </div>
            </button>
          ))}
        </div>

        {/* Form display section */}
        <div className="w-5/6 p-2 flex-grow">
          {forms[activeForm]}
        </div>
      </div>
    </div>
  );
})

export default Admin;
