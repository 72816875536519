import React from 'react';
import { useSelector } from 'react-redux';

const ClientsHistoryData = ({clientData, archives, closeClientData}) => {
  
    const client = archives.find(item=>item._id === clientData)
  console.log(client)
   
  return (
    <div className='flex flex-col'>
      <button className='justify-end border border-white' onClick={()=>closeClientData()}>Close detail</button>
        <ul className='text-sm my-2 font-bold'>

        <li className=" flex flex-row justify-between  border-b-white "><span>Date:</span>{new Date(client.date).toLocaleDateString('sr-RS', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
            </li>
            <li className='flex flex-row justify-between'><span>Time:</span>{client.time}</li>
            <li className='flex flex-row justify-between'><span>Service:</span> {client.service}</li>
            <li className='flex flex-row justify-between'><span>Price:</span>{client.price}</li>
            <li className='flex flex-row justify-between'><span>Worker:</span> {client.workerFirstName} {client.workerLastName}</li>
            <li className='flex flex-row justify-between'><span>Comment:</span> {client.comment}</li>
    
        {/*    <div className="flex flex-col">
            <h5 className=''>Images:</h5>
            <div className="flex flex-row mt-3">
                <img src={client.image1} className='w-[150px] h-[250px] m-1' alt="Image1" />
                <img src={client.image2} className='w-[150px] h-[250px] m-1' alt="Image2" />
            </div>
            <div className="flex flex-row">
            <img src={client.image3} className='w-[150px] h-[250px] m-1' alt="Image3" />
            <img src={client.image4} className='w-[150px] h-[250px] m-1' alt="Image4" />
            </div>
        </div> */}   </ul>
        
    </div>
  )
}

export default ClientsHistoryData