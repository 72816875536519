import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteCategory } from '../redux/features/categorySlice';
import {toast} from 'react-toastify';
import CategoryData from './CategoryData'
const Categories = ({categories}) => {
    const dispatch = useDispatch();
    const [showData, setShowData] = useState(false);
    const [categoryData, setCategoryData] = useState();
    const openShowData = (id)=>{
      const selectedCategory = categories.find(category => category._id === id);
      setCategoryData(selectedCategory); 
      setShowData(true); 
    }
    const handleDelete = (id) =>{
        if(window.confirm('Are you sure you want to delete this category?',id)){
            dispatch(deleteCategory({id, toast}))
        }
          }
  return (
    <div> 
       {categories.map((item,index)=>(
        <ul className="flex flex-col">
        <li key={item._id} className='flex flex-row justify-between font-bold border border-black'
        style={{backgroundColor:item.colorFinish, padding:'2px'}}>{index+1}. {item.category}
        <div className="flex flex-row">
        <button className="text-sm hover:text-base hover:text-gray-900 mx-1">
                          <Link to={`/editCategory/${item._id}`}>
                            <FontAwesomeIcon icon={faEdit} />
                          </Link>
                        </button>
                        <button className="text-sm hover:text-base hover:text-gray-900 mx-1"  
                        onClick={() => handleDelete(item._id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                        <button
            className="text-sm hover:text-base hover:text-gray-900"
            onClick={() => openShowData(item._id)}
          >
            <i>View..</i>
          </button></div>
        </li>
        </ul>
        
      ))}
       {showData && (
    <CategoryData categoryData={categoryData} closePopup={() => setShowData(false)}/>
    )}
     </div>
  )
}

export default Categories;