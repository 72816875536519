import React , {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector,useDispatch } from 'react-redux';
import FileBase from "react-file-base64";
import { faPhone, faEnvelope, faTimes, faClock, faUserTie, faUser, faMoneyBill, faBrush } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';
import { toast } from 'react-toastify';
import AddArchive from '../forms/AddArchive';
import { createArchive } from '../redux/features/archiveSlice';
import { deleteSession } from '../redux/features/sessionSlice';
const SessionData = ({sessionData, closePopup}) => {
  const [archiveData, setArchiveData] = useState(sessionData);
  const [comment, setComment] = useState("");
  const user = useSelector(selectUser);
  const [sessionView, setSessionView] = useState(false);
  const dispatch = useDispatch();
  const formattedDate = new Date(sessionData.createdAt).toLocaleDateString('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
  const sessionDate = new Date(sessionData.date).toLocaleDateString('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  
  });
  const onInputChange = (e) => {
    const { value } = e.target;
    setComment(value);
}
  const saveSession = async() =>{
    const sessionId = sessionData._id;
    try {
      const updatedArchiveData = {
        ...archiveData,
        comment
      };
      const createResult = await dispatch(createArchive({ archiveData:updatedArchiveData, toast }));
  
      if (createResult.meta.requestStatus === "fulfilled") {
        // Ako je kreiranje arhive uspelo, pokreni brisanje sesije
        const deleteResult = await dispatch(deleteSession({ id: sessionId, toast }));
  
        if (deleteResult.meta.requestStatus === "fulfilled") {
        } else {
          toast.error("Failed to delete the session!");
        }
      } else {
        toast.error("Failed to archive the session!");
      }
    } catch (error) {
      toast.error("An error occurred!");
    }
    closePopup();
  }
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center text-sm z-50">
    <div 
      className="bg-white rounded-sm shadow-lg p-2 w-11/12 md:w-1/3"
      onClick={(e) => e.stopPropagation()} // Sprečava zatvaranje kada se klikne unutar popupa
    >
     <div> <h2 className="text-xl font-bold mb-1">All Session Data</h2>
      <div className="min-w-full bg-transparent rounded-sm shadow-lg bg-gray-50">
      <ul className="border-b border-transparent last:border-none">
      <div className='flex flex-row justify-between'> 
         <li className="px-2 py-1 text-black">{sessionDate} | {sessionData.time}</li><FontAwesomeIcon className='p-2' icon={faClock} />
    </div>  <hr/>
    <div className='flex flex-row justify-between'> 
        <li className="px-2 py-1 text-black">{sessionData.firstName} {sessionData.lastName}</li><FontAwesomeIcon className='p-2' icon={faUser} />
        </div><hr/>
        <div className='flex flex-row justify-between'>  <li className="px-2 py-1 text-black">{sessionData.service}</li><FontAwesomeIcon className='p-2' icon={faBrush} />
        </div><hr/>
        <div className='flex flex-row justify-between'> <li className="px-2 py-1 text-black">{sessionData.price}</li><FontAwesomeIcon className='p-2' icon={faMoneyBill} /></div>
        <hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{sessionData.phone}</li>
      <a href={`tel:${sessionData.phone}`} ><FontAwesomeIcon className='p-2' icon={faPhone} /></a></div><hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{sessionData.email}</li>
      <a href={`mailto:${sessionData.email}`} ><FontAwesomeIcon className='p-2' icon={faEnvelope} /></a></div><hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{sessionData.workerFirstName} {sessionData.workerLastName}</li>
      <FontAwesomeIcon className='p-2' icon={faUserTie} /></div>
  <hr/>
      <li className="px-2 py-1 text-black"><i>Created <b className='text-sm'>{formattedDate}</b> by  <b className='text-sm'>{user?.result?.email}</b></i></li><hr/>
      </ul>
      <div className="flex flex-col m-1 w-full p-1">
      <p className='text-sm font-bold'>Images from session (max 4): </p>
            <FileBase type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setArchiveData({ ...archiveData, image1: base64 })
                }
              />
               <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setArchiveData({ ...archiveData, image2: base64 })
                }
              />
        <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setArchiveData({ ...archiveData, image3: base64 })
                }
              />
                <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setArchiveData({ ...archiveData, image4: base64 })
                }
              />
                <textarea
                onChange={onInputChange}
          placeholder="Description.."
          className="mt-1 w-full h-24 md:h-[50px] border border-black p-2 font-bold rounded-md resize-none"
        />
       
    </div>
      </div>
      <button 
        onClick={saveSession} 
        className="mt-1 px-4 py-1 justify-end w-full bg-green-900 text-white rounded-sm"
      >
        Complete Session 
      </button></div>
      <div className='flex justify-end'>
      <button 
        onClick={closePopup} 
        className="px-2 py-1 mt-0.5 bg-black text-white rounded-sm"
      >
        x
      </button>

      </div>
    </div>
   
  </div>
  )
}

export default SessionData