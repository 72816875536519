//Opciono 

import React, {useState, useEffect, useCallback} from 'react';
import PhoneInput from 'react-phone-input-2';
import FileBase from 'react-file-base64';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import 'react-phone-input-2/lib/style.css';
import { createClient, updateClient } from '../redux/features/clientSlice';
import HeaderAdmin from '../components/HeaderAdmin';
import TimeInput from '../components/DatePicker';
import { updateArchive } from '../redux/features/archiveSlice';
import { fetchArchiveImages } from '../redux/api';

const initialState = {
    firstName:'',
    lastName:'',
    phone:'',
    email:'',
    date:'',
    time:'',
    price:'',
    workerFirstName:'',
    workerLastName:'',
    service:'',
    image1:'',
    image2:'',
    image3:'',
    image4:''
}

const EditArchive = ({goToClients})=> {
    
    const [archivedData, setArchivedData] = useState(initialState);
    const [isOpenWorker, setIsOpenWorker] = useState(false);
    const [isOpenService, setIsOpenService] = useState(false);
    const [imagesBox, setImagesBox] = useState(false);
    const [selectedWorker, setSelectedWorker] = useState(null); 
    const [selectedService, setSelectedService] = useState(null); 
    const error = useSelector((state) => state.session.error);
    const archives = useSelector((state) => state.archive.archives);
   const [archiveImages, setArchiveImages] = useState([]);
   console.log(archiveImages);
   
    const user = useSelector((state) => state.auth.user);
    const workers = useSelector((state) => state.worker.workers);
    const services = useSelector((state) => state.service.services);
    const [selectedImage, setSelectedImage] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [numb, setNumb] = useState(null);

    const handleImageClick = (imageSrc,numb) => {
      setNumb(numb)
      setSelectedImage(imageSrc);
    };
    const finishSetImage = (base64) =>{
      setNewImage({ newImage: base64 });
     
    }
    useEffect(() => {
      if(numb === 1){
        setArchivedData({...archivedData, image1:newImage.newImage});
      }else if(numb === 2){
        setArchivedData({...archivedData, image2:newImage.newImage});
      }
      else if(numb === 3){
        setArchivedData({...archivedData, image3:newImage.newImage});
      }
      else if(numb === 4){
        setArchivedData({...archivedData, image4:newImage.newImage});
      }
      closeBackdrop();
    }, [newImage])
    const loadImagesBox = async()=>{
      setImagesBox(!imagesBox);
      try {
        const images = await fetchArchiveImages(id); // Koristimo await jer je fetchArchiveImages asinhrona
        console.log("Dohvaćene slike:", images.data);
        setArchiveImages(images.data); // Postavljamo slike u stanje
      } catch (error) {
        console.error("Greška prilikom učitavanja slika:", error);
      }
    }
   
    const closeBackdrop = () => {
      console.log(1);
      
      setSelectedImage(null);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
useEffect(()=>{
    const singleArchive = archives.find((session)=> session._id === id);
    setArchivedData({...singleArchive})
},[])

    const { firstName, lastName, phone, email, price, date, time, workerFirstName, workerLastName, service, image1, image2, image3, image4} = archivedData;
    const choosenCategory = services.find((item)=> item.service === service);
    useEffect(()=>{
    error && toast.error(error)
    },[error])
    const toggleDropdownWorker = () => {
        setIsOpenWorker(!isOpenWorker);
      };
      const handleSelectWorker = (worker) => {
        
        setSelectedWorker(worker); 
        setArchivedData((prevState) => ({
          ...prevState,
          workerFirstName: worker.firstName,
          workerLastName: worker.lastName,
      }));
        setIsOpenWorker(false); 
      };
      const toggleDropdownService = () => {
        setIsOpenService(!isOpenService);
      };
      const handleSelectService = (service) => {
        setSelectedService(service); 
          setArchivedData((prevState) => ({
            ...prevState,
            service: service.service,
            price: service.price
        }));
        setIsOpenService(false); 
      };
      const onInputChange = (e) =>{
        const {name, value} = e.target;
        setArchivedData({...archivedData, [name]: value})
      };
        const handleTimeChange = useCallback((selectedTime) => {
            setArchivedData((prevState) => ({
              ...prevState,
              time: selectedTime 
            }));
          });
      const handleSubmit = (e) =>{
          e.preventDefault();          
            const updatedArchiveData = {...archivedData, name:user?.result?.name}
            console.log(updatedArchiveData);
            
        dispatch(updateArchive({id, updatedArchiveData, navigate, toast}))  
            handleClear();
          }
      const handleClear = () =>{
        setArchivedData({firstName:'', lastName:'', phone:'', email:'', date:'', time:'', price:'', workerFirstName:'', workerLastName:'', service:'', image1:'', image2:'', image3:'',image4:''})
      };

  return (
<div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200 overflow-y-auto">
      {/* Header */}
      <HeaderAdmin />
      <div className="w-full md:p-8 p-4  fixed top-0 left-0 z-10">
        <h2 className="text-3xl font-bold text-white mb-6 mt-[80px]">Edit Archive</h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full" noValidate>
            
            <div className="flex flex-row  w-full">
            <div className='md:w-1/2 w-full'>
            <label htmlFor="firstName" className="block text-xs font-medium text-gray-100">First Name</label>
            <input onChange={onInputChange} value={firstName} id="firstName"  name="firstName" type="text" className=" w-full px-4 py-1 border border-gray-200 rounded-md shad3w-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/2 w-full'>
            <label htmlFor="lastName" className="block text-xs font-medium text-gray-100">Last Name</label>
            <input onChange={onInputChange} value={lastName} id="lastName" name="lastName" type="text" className=" w-full px-4 py-1 border border-gray-200 rounded-md shad3w-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          
            </div>
            <div className="flex flex-row w-full">
            <div className='md:w-1/2 w-full'>
  <label htmlFor="phone" className="block text-xs font-medium text-gray-200">Phone Number</label>
  <input
    type="text"
    id="phone"
    name="phone"
    value={phone}
    onChange={onInputChange}
    className=" w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none"
    placeholder="+1234567890" 
  />
</div>
          <div className='md:w-1/2 w-full '>
            <label htmlFor="email" className="block text-xs font-medium text-gray-100">Email</label>
            <input onChange={onInputChange} value={email} id="email" name="email" type="text" className=" w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
</div>
<div className="flex flex-row  w-full">
<div className='md:w-1/2 w-full'>
            <label htmlFor="price" className="block text-xs font-medium text-gray-100">Category</label>
            <input onChange={onInputChange} value={choosenCategory?.category} id="price" name="price" type="text" className=" w-full px-2 py-1 border border-gray-200 rounded-md shad3w-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          
<div className='relative md:w-1/2 w-full '>
      <label htmlFor="service" className="block text-xs font-medium text-gray-100">Service</label>
      <div 
        className=" w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none bg-white text-black cursor-pointer"
        onClick={toggleDropdownService}
      >
        {service}
      </div>
      
      {isOpenService && (
        <ul 
          className="absolute bottom-full w-full max-h-40 bg-white border border-gray-500 rounded-smoverflow-auto z-10 shadow-lg"
        >
         {services.length > 0 ? (services.map((service) => (<li key={service._id} 
      className="flex justify-between cursor-pointer hover:bg-green-500 bg-green-100  text-black p-2"
      onClick={() => handleSelectService(service)}>
      {service.service} 
    </li>
  ))
) : (
  <p className="text-green-500 p-2">No services available.</p>
)}
<hr />
        </ul>
      )}
    </div>
  
       
            </div>
<div className="flex flex-row  w-full">
<div className='md:w-1/2 w-full'>
            <label htmlFor="price" className="block text-xs font-medium text-gray-100">Price</label>
            <input onChange={onInputChange} value={price} id="price" name="price" type="text" className=" w-full px-4 py-1 border border-gray-200 rounded-md shad3w-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
    <div className='relative md:w-1/2 w-full'>
      <label htmlFor="worker" className="block text-xs font-medium text-gray-100">Worker</label>
      <div 
        className=" w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none bg-white text-black cursor-pointer"
        onClick={toggleDropdownWorker}
      >
        {/* Prikaži izabranog radnika ili default poruku */}
        {workerFirstName} {workerLastName}
      </div>
      
      {isOpenWorker && (
        <ul 
          className="absolute bottom-full w-full max-h-40 bg-white border border-gray-500 rounded-smoverflow-auto z-10 shadow-lg"
        >
         {workers.length > 0 ?  (workers.map((worker) => (
            <li 
              key={worker._id} 
              className="flex justify-between  cursor-pointer hover:bg-green-500 bg-green-100 text-black p-2"
              onClick={() => handleSelectWorker(worker)}
            >
            {worker.firstName} {worker.lastName}
            </li>
          ))):( <p className="text-green-500 p-2"></p>)}<hr />
        </ul>
      )}
    </div> 
       
            </div>
            <div className="flex flex-row">
          <div className='md:w-1/2 w-full'>
             <label htmlFor="date" className="block text-xs font-medium text-gray-100">Date</label>
             <input onChange={onInputChange} value={date} id="date" name="date" type="date" required className="w-full py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
 </div>
 <div className='md:w-1/2 w-full'>
             <label htmlFor="time" className="block text-xs font-medium text-gray-100">Time</label>
             <TimeInput onTimeChange={handleTimeChange} />
 </div>
            </div>
            {imagesBox && (
                <div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
              >
                <div className="relative w-[80%] h-[80%]">
              {!selectedImage && 
              <div className="flex flex-col justify-center mt-24">
              <p className='bg-white py-1 text-center font-bold md:w-[250px]'>Select image for edit</p>
      
          {archiveImages && archiveImages.map(item=> <div>
            <div className="flex flex-row justify-center mt-2 ">
   <img src={item.image1} className='p-1 w-[100px] h-[120px]'  onClick={() => handleImageClick(item.image1,1)} alt="No image" />
      <img src={item.image2} className='p-1 w-[100px] h-[120px]'  onClick={() => handleImageClick(item.image2,2)} alt="No image" /></div>
      <div className="flex flex-row justify-center">
      <img src={item.image3} className='p-1 w-[100px] h-[120px]'  onClick={() => handleImageClick(item.image3,3)} alt="No image" />
      <img src={item.image4} className='p-1 w-[100px] h-[120px]'  onClick={() => handleImageClick(item.image4,4)} alt="No image" />
     </div>
          </div>
      
          )}
     
     <p  onClick={()=>setImagesBox(!imagesBox)} className='bg-black py-1 text-center font-bold md:w-[250px] text-white'>Submit</p>

     </div>} {selectedImage &&
     <div className="flex flex-col justify-center mt-24">
     <img src={selectedImage} className='p-1 w-[200px] h-[240px]' alt="No image" />
     <FileBase  
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  finishSetImage(base64)
                }
              />
             
    </div>
     } 
     
          
                </div>
              </div>
            )
            }
           <p onClick={loadImagesBox} 
            className='text-black bg-white m-0 p-1 w-full text-center font-bold border border-black'>Change Images</p>
          <button type="submit" className="w-full flex justify-center py-1 px-4 border border-transparent rounded-md shadow-xs text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit all changes
          </button>
        </form>
      </div></div>  
  )
}

export default EditArchive;