import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
.use(Backend)
.use(initReactI18next)
.use(LanguageDetector)
.init({
    debug:true,
    fallBackLang: 'en',
    interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: "/locales/{{lng}}/translation.json", // Gde su prevodi
      },
})

export default i18n;

