import React, {useState , useEffect} from 'react'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Serbian } from "flatpickr/dist/l10n/sr.js";
const DateMobile = ({birthDay, forwardNewBirth}) => {
    
    const [newDate, setNewDate] = useState("dd-mm-yyyy" );
    
    const formattedDate = new Date(birthDay).toLocaleDateString('sr-RS', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

      useEffect(() => {
        if (newDate) {
          forwardNewBirth(newDate);
        }
      }, [newDate]);
      console.log(formattedDate);
     
  return (
    <Flatpickr
    id="birth"
    className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
    placeholder={formattedDate} // Ovo je vrednost koja je sada kontrolisana od strane birthInput
    options={{
      dateFormat: "d.m.Y",
      locale: Serbian,
      disableMobile: true,
      allowInput: true,
    }}
    onChange={(selectedDates) => {
      if (selectedDates.length > 0) {
        const date = selectedDates[0];
        const formattedDate = date.toISOString().split("T")[0];
        setNewDate(formattedDate); // Ažurira lokalno stanje
      }
    }}
  />
  )
}

export default DateMobile