import React, { useEffect, useState } from 'react';
import { fetchArchiveImages } from '../redux/features/archiveSlice';
import { useDispatch, useSelector } from 'react-redux';
import { deleteArchive } from '../redux/features/archiveSlice';
import { selectUser } from '../redux/features/authSlice';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faBars } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import SessionsHistoryData from './SessionsHistoryData';
const SessionsHistory = ({goToSessions, archives}) => {
    const [showData, setShowData] = useState();
    const [archiveData, setArchiveData] = useState();
    const [archiveImages, setArchiveImages] = useState([]);
    console.log(archiveImages);
    console.log(archives);
    
    const getCurrentMonth = () => {
      const today = new Date();
      return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}`;
    };
    const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());

    const user = useSelector(selectUser);
    const userId = user?.result._id;
    const dispatch = useDispatch();

    const handleDelete = (id) =>{
        if(window.confirm('Are you sure you want to delete this session?',id)){
            dispatch(deleteArchive({id, toast}))
        }
          }
          const openShowData =async(id)=>{
            try {
              const images = await fetchArchiveImages(id);     
              setArchiveImages(images); 
            } catch (error) {
              console.error("Greška prilikom učitavanja slika:", error);
            }
          
            const selectedArchive = archives.find(client => client._id === id);
            setArchiveData(selectedArchive); 
            setShowData(true); 
          }

          const handleMonthChange = (e) => {
            setSelectedMonth(e.target.value);
          };
        
        
          const filteredArchives = archives.filter((item) => {
            if (!selectedMonth) return true; // Ako nije izabran mesec, prikaži sve
            const itemDate = new Date(item.date); // Pretpostavljamo da 'date' postoji u objektu
            const itemYearMonth = `${itemDate.getFullYear()}-${String(itemDate.getMonth() + 1).padStart(2, "0")}`;
            return itemYearMonth === selectedMonth;
          });
          
          const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, "0"); // Dodaj 0 ispred dana ako je potrebno
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Dodaj 0 ispred meseca ako je potrebno
            return `${day}.${month}`; // Format: dd.mm.yyyy
          };
  return (
    <div className="">
    <h2 className="text-xl font-bold text-white mb-1">Archived Sessions</h2>
    <label htmlFor="monthPicker" className="text-white font-bold mr-2">Select Month:</label>
      <input
        type="month"
        id="monthPicker"
        value={selectedMonth}
        onChange={handleMonthChange}
        className="border border-gray-300 rounded-md p-1"
      />
        <ul className="mt-4 text-sm font-bold text-white">
        {filteredArchives.map((archive, index) => (
          <div>
         <li className="flex flex-row p-1 justify-between">{`${ new Date(archive.date).toLocaleDateString('sr-RS', {
          day: '2-digit',
          month: '2-digit',
        })}`
       } <span>{archive.firstName} {archive.lastName}</span>
          <span className="flex flex-row space-x-2">
                     <button className="text-sm hover:text-base  hover:text-gray-900" onClick={()=>handleDelete(archive._id)}>
                       <FontAwesomeIcon icon={faTrash} />
                     </button>
                      <button className="text-sm hover:text-base  hover:text-gray-900" onClick={()=>openShowData(archive._id)}>
                      <FontAwesomeIcon icon={faBars} />
                     </button>
                   </span></li><hr/></div>
        ))}
      </ul>
     <div className="overflow-x-auto w-full">
      
     </div>
     {showData && (
<SessionsHistoryData archives={archiveData} archiveImages={archiveImages}  closePopup={() => setShowData(false)}/>
)}
   </div>
  )
}

export default SessionsHistory